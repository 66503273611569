import { Delete, Edit, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { bonusDetailsToBonusDetailsInput, formatValue, operationsToOperationsInput } from "../../data/dataConvertors";
import {
  BonusDetailsInput,
  DataFieldInput,
  Maybe,
  Operation,
  OperationInput,
  Sector,
} from "../../data/generated/graphql";
import GdButton from "../../utils/GdButton";
import { getApplicableForm, isLatestForm } from "../ProjectHelper";
import BonusModal, { JustificationType } from "./BonusModal";
import { formIsPreview } from "./FormHelper";
import { Form, getAttachments, getDisplayableFields } from "./formsTypes";

interface OperationsViewerProps {
  zipCode: string;
  operations: OperationInput[] | Operation[];
  dontTouchOperations: boolean;
  replaceOperations: (newOperations: OperationInput[]) => Promise<void>;
  deleteLoading?: string;
  sector?: Maybe<Sector>;
  editOperation?: (o: OperationInput) => void;
  removeOperation?: (o: OperationInput, newIsDeleted: boolean) => void;
  updateOperationCpe?: (o: OperationInput) => void;
  addCpeCheckbox?: boolean;
  isReadOnly?: boolean;
  originalOperations?: Operation[];
}

const OperationsViewer: FC<OperationsViewerProps> = ({
  sector,
  zipCode,
  operations,
  editOperation,
  replaceOperations,
  removeOperation,
  addCpeCheckbox,
  updateOperationCpe,
  isReadOnly,
  dontTouchOperations,
  deleteLoading,
  originalOperations,
}) => {
  const { t } = useTranslation("project");
  const [operationToUpdate, setOperationToUpdate] = useState<OperationInput | undefined>(undefined);
  const [bonusToUpdate, setBonusToUpdate] = useState<BonusDetailsInput | undefined>(undefined);
  const [bonusUpdateIndex, setBonusUpdateIndex] = useState<number | undefined>(undefined);
  const [editIsLoading, setEditIsLoading] = useState<boolean>(false);
  const [deleteIsLoading, setDeleteIsLoading] = useState<number | null>(null);

  const editBonus = async (bonusDetails: BonusDetailsInput[], operationId: string): Promise<void> => {
    const operationIndex = operations?.findIndex((o) => o?.id === operationId);
    const newBonusDetails = bonusDetails.map((b) => {
      return bonusDetailsToBonusDetailsInput(b);
    });
    const newOperation: Operation = {
      ...operations[operationIndex],
      bonusDetails: newBonusDetails,
    };
    const tempOperations = [
      ...operations.slice(0, operationIndex),
      newOperation,
      ...operations.slice(operationIndex + 1),
    ];
    await replaceOperations(operationsToOperationsInput(tempOperations as Operation[]));
  };

  const addBonus = async (newBonus: BonusDetailsInput): Promise<void> => {
    const newBonusDetails = Array.isArray(operationToUpdate?.bonusDetails)
      ? (operationToUpdate?.bonusDetails?.map((bd, idx) => {
          if (idx === bonusUpdateIndex) return newBonus;
          return bd;
        }) as BonusDetailsInput[])
      : [];
    if (typeof bonusUpdateIndex === "undefined") newBonusDetails.push(newBonus);
    setEditIsLoading(true);
    await editBonus(newBonusDetails, operationToUpdate?.id || "");
    setOperationToUpdate(undefined);
    setBonusToUpdate(undefined);
    setBonusUpdateIndex(undefined);
    setEditIsLoading(false);
  };

  const updateBonus = (operation: OperationInput, bonusIndex: number): void => {
    const bonusToEdit = operation.bonusDetails?.[bonusIndex] as BonusDetailsInput;
    setBonusToUpdate(bonusToEdit);
    setBonusUpdateIndex(bonusIndex);
    setOperationToUpdate(operation);
  };

  const deleteBonus = async (operation: OperationInput, bonusIndex: number): Promise<void> => {
    setDeleteIsLoading(bonusIndex);
    const tempBonusDetails = operation.bonusDetails?.slice(0);
    const newBonusDetails = tempBonusDetails?.filter((_, idx) => idx !== bonusIndex) as BonusDetailsInput[];
    await editBonus(newBonusDetails, operation.id);
    setDeleteIsLoading(null);
  };
  return (
    <div style={{ overflowY: "auto", padding: 4 }}>
      {operations.map((o) => {
        const form = getApplicableForm(
          o,
          sector || Sector.Ind,
          zipCode,
          originalOperations?.find((orignalOperation) => orignalOperation.id === o.id),
        );
        if (!form) return undefined;
        const fields = getDisplayableFields(form?.fields || [], o.data);
        const { computation } = o as Operation;
        const attachments = getAttachments(form as Form, o.data as DataFieldInput[]);
        const isStillValid = dontTouchOperations || isLatestForm(o, sector || Sector.Ind, zipCode);
        return (
          <Accordion key={`${o.id}${o.formId}`}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              sx={{
                display: "flex",
                alignItems: "center",
                textDecoration: o.isDeleted === true ? "line-through" : undefined,
                background: o.isDeleted ? "#E0E0E0" : undefined,
              }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {o.machineName ? (
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "20px",
                      fontStyle: "italic",
                      fontWeight: "bold",
                    }}>
                    ({o.machineName})
                  </Typography>
                ) : (
                  ""
                )}
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      paddingRight: "16px",
                    }}>
                    {o.id} - <b style={{ margin: "0 4px" }}>{o.formId}</b>
                    {computation
                      ? ` : ${formatValue(computation.capacity, true, 3, false)} MWhca${
                          computation.valuation === "0"
                            ? ""
                            : ` / ${formatValue(computation.valuation, false, 2, true)} €`
                        }${attachments.length === 0 ? "" : ` / ${t("attachments", { count: attachments.length })}`}`
                      : undefined}
                    {computation?.cpeBonus &&
                      computation.cpeBonus !== "undefined" &&
                      computation.cpeBonus !== "null" &&
                      t("cpeBonus", { count: parseInt(computation.cpeBonus, 10) })}
                    {o.bonusDetails &&
                      o.bonusDetails?.length > 0 &&
                      `/ ${o.bonusDetails?.length} ${t("bonificationPrefix")}`}
                  </Typography>
                  {addCpeCheckbox && (sector === Sector.Bat || sector === Sector.Bar) ? (
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        checked={o.isCpeScoped || false}
                        label={o.isCpeScoped ? t("operationWithCpe") : t("operationWithoutCpe")}
                        onClick={(e) => e.stopPropagation()}
                        onFocus={(e) => e.stopPropagation()}
                        onChange={(_, newChecked) => {
                          const tempOperation = { ...o };
                          tempOperation.isCpeScoped = newChecked;
                          updateOperationCpe?.(tempOperation);
                        }}
                        disabled={isReadOnly}
                      />
                    </FormGroup>
                  ) : undefined}
                  {formIsPreview(form) ? (
                    <Typography sx={{ color: "red" }}>{t("operationIsPreview")}</Typography>
                  ) : !isStillValid ? (
                    <Typography sx={{ color: "red" }}>{t("invalidComputation")}</Typography>
                  ) : undefined}
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails sx={{ background: o.isDeleted ? "#E0E0E0" : undefined }}>
              <div className="row space-between margin-bottom">
                <Typography variant="h6">{form?.name}</Typography>
                <GdButton
                  label={t("seeForm")}
                  onClick={() => window.open(form?.formUrl)}
                  className="margin-left"
                  nowrap
                />
              </div>
              {fields.map((ff) => (
                <div key={ff.id} style={{ textAlign: "center", marginBottom: "16px" }}>
                  <Typography variant="body2">
                    {ff.titles
                      ? ff.titles.find((ft) => ft.fieldValue === o.data?.find((d) => d?.fieldId === ft.fieldId)?.value)
                          ?.title
                      : ff.title}
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {ff.type === "number" || ff.type === "range"
                      ? formatValue(o.data.find((d) => d?.fieldId === ff.id)?.value, true, 3)
                      : ff.choices?.find((fc) => fc.value === o.data.find((d) => d?.fieldId === ff.id)?.value)?.name}
                  </Typography>
                </div>
              ))}
              {attachments.length === 0 ? undefined : (
                <>
                  <Typography variant="body2">{t("attachmentList", { count: attachments.length })}</Typography>
                  <ul>
                    {attachments.map((a) => (
                      <li key={a}>
                        <Typography variant="body2">{a}</Typography>
                      </li>
                    ))}
                  </ul>
                </>
              )}
              <div className="big-margin-top">
                {o.bonusDetails && o.bonusDetails?.length > 0 ? (
                  <div className="margin-left">
                    <Typography style={{ textDecoration: "underline" }}>{t("bonus.list")}</Typography>
                    <ul style={{ marginTop: "0" }}>
                      {o.bonusDetails.map((b, index) => (
                        <li key={`bonus_${b?.justification}_${b?.multiplicator}`}>
                          <div className="row" style={{ justifyContent: "space-between" }}>
                            <div className="row" style={{ gap: "8px" }}>
                              <Typography>
                                {b?.justification === JustificationType.cdp ||
                                b?.justification === JustificationType.zni
                                  ? t(`bonus.justification.${b?.justification}`)
                                  : b?.justification}
                                :
                              </Typography>
                              {b?.multiplicator && <Typography>x {b?.multiplicator}</Typography>}
                              {b?.addition && <Typography>+ {b?.addition} MWhca</Typography>}
                            </div>
                            {o.isDeleted ? undefined : (
                              <div className="row" style={{ gap: "8px" }}>
                                <Tooltip arrow title={t("bonus.edit")}>
                                  <IconButton sx={{ padding: "0" }} onClick={() => updateBonus(o, index)}>
                                    <Edit />
                                  </IconButton>
                                </Tooltip>
                                {deleteIsLoading === index ? (
                                  <CircularProgress size={24} />
                                ) : (
                                  <Tooltip arrow title={t("bonus.delete")} onClick={() => deleteBonus(o, index)}>
                                    <IconButton sx={{ padding: "0" }}>
                                      <Delete />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </div>
                            )}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <Typography>{t("bonus.none")}</Typography>
                )}
              </div>
              <div className="row space-evenly">
                {editOperation ? (
                  <>
                    <GdButton
                      label={t(o.isDeleted ? "finalOperations.resetOperation" : "finalOperations.removeOperation")}
                      onClick={() => removeOperation?.(o, !o.isDeleted)}
                      color={o.isDeleted ? "primary" : "inherit"}
                      isLoading={deleteLoading === o.id}
                    />
                    {o.isDeleted ? undefined : (
                      <GdButton label={t("finalOperations.editOperation")} onClick={() => editOperation(o)} />
                    )}
                  </>
                ) : undefined}
                {o.isDeleted ? undefined : (
                  <GdButton
                    label={t("bonus.add")}
                    onClick={() => {
                      setOperationToUpdate(o);
                      setBonusToUpdate(undefined);
                      setBonusUpdateIndex(undefined);
                    }}
                  />
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        );
      })}
      <BonusModal
        open={operationToUpdate !== undefined}
        onClose={() => setOperationToUpdate(undefined)}
        bonus={bonusToUpdate}
        addBonus={addBonus}
        isLoading={editIsLoading}
      />
    </div>
  );
};

OperationsViewer.defaultProps = {
  editOperation: undefined,
  removeOperation: undefined,
  addCpeCheckbox: undefined,
  updateOperationCpe: undefined,
  isReadOnly: undefined,
  sector: undefined,
  deleteLoading: undefined,
  originalOperations: undefined,
};

export default OperationsViewer;
