import { Maybe, Sector } from "../../data/generated/graphql";
import { Form } from "./formsTypes";
import indForms, { activeForms as indActiveForms } from "./data/indForms";
import agriForms, { activeForms as agriActiveForms } from "./data/agriForms";
import batForms, { activeForms as batActiveForms } from "./data/batForms";
import traForms, { activeForms as traActiveForms } from "./data/traForms";
import resForms, { activeForms as resActiveForms } from "./data/resForms";
import barForms, { activeForms as barActiveForms } from "./data/barForms";
import { getMetDomTom } from "../../data/dataConvertors";

export const getFormsForSector = (sector?: Maybe<Sector>, zipCode?: string): Form[] => {
  const today = new Date().toISOString();
  const stillAliveFilter = (f: Form): boolean => !f.endDate || f.endDate < today;
  const metDomTom = zipCode ? getMetDomTom(zipCode) : 0;
  const metDomTomFormsFilter = (f: Form): boolean => !f.metDomTom || f.metDomTom === metDomTom;
  switch (sector) {
    case Sector.Ind:
      return indForms.filter(metDomTomFormsFilter).filter(stillAliveFilter);
    case Sector.Agri:
      return agriForms.filter(metDomTomFormsFilter).filter(stillAliveFilter);
    case Sector.Bat:
      return batForms
        .filter(metDomTomFormsFilter)
        .filter(stillAliveFilter)
        .concat(
          indForms.filter(
            (f) =>
              f.id === "IND-BA-112" ||
              f.id === "IND-UT-113" ||
              f.id === "IND-UT-115" ||
              f.id === "IND-UT-116" ||
              f.id === "IND-UT-117" ||
              f.id === "IND-UT-136",
          ),
        );
    case Sector.Tra:
      return traForms.filter(metDomTomFormsFilter).filter(stillAliveFilter);
    case Sector.Res:
      return resForms.filter(metDomTomFormsFilter).filter(stillAliveFilter);
    case Sector.Bar:
      return barForms.filter(metDomTomFormsFilter).filter(stillAliveFilter);
    default:
      return [];
  }
};

export const getActiveFormsId = (sector?: Maybe<Sector>): string[] => {
  switch (sector) {
    case Sector.Ind:
      return indActiveForms;
    case Sector.Agri:
      return agriActiveForms;
    case Sector.Bat:
      return batActiveForms;
    case Sector.Tra:
      return traActiveForms;
    case Sector.Res:
      return resActiveForms;
    case Sector.Bar:
      return barActiveForms;
    default:
      return [];
  }
};

export const formIsPreview = (form?: Form): boolean => {
  const today = new Date().toISOString();
  const isPreview = (form?.startDate && form.startDate > today && (form.history?.length || 0) === 0) || false;
  return isPreview;
};
